import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { useRouter } from 'next/router';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import PlacesAutocomplete, { getLatLng } from 'react-places-autocomplete';

import MapCordinateContext from '@/context/MapCordinateContext';
import {
  SEARCH_PLACEHOLDER_EN,
  SEARCH_PLACEHOLDER_FR,
} from '@/utils/constants';
import defaultCenters from '@/utils/default';

import styles from './style.module.scss';

const SearchInput = () => {
  const { locale, push } = useRouter();
  const [address, setAddress] = useState('');
  const [isScrolled, setIsScrolled] = useState(false);
  const cords = useContext(MapCordinateContext);

  const searchOptions = {
    componentRestrictions: { country: ['ca'] },
  };

  const handleChange = async (value: string) => {
    setAddress(value);
  };

  const performGeocode = (inputAddress1: string) => {
    const googleMapsGeocoder = new google.maps.Geocoder();
    let inputAddress = inputAddress1;
    googleMapsGeocoder.geocode(
      { address: inputAddress, componentRestrictions: { country: 'ca' } },
      (results) => {
        if (results && results.length > 0) {
          getLatLng(results[0]).then((latLng) => {
            if (inputAddress.includes('#')) {
              inputAddress = inputAddress.replace('#', '');
            }
            const finalLatLng = latLng ?? defaultCenters[0].center;
            const coords = finalLatLng;
            cords.setLatLangs(coords);
            cords?.setSisterInput(false);
            push(
              `/self-storage/?location=${inputAddress}&lat=${coords.lat}&lng=${coords.lng}`
            );
          });
        }
      }
    );
  };

  const handleClick = (searchItem: string) => {
    const inputAddress = searchItem && searchItem.length ? searchItem : address;
    performGeocode(inputAddress);
  };

  const handleSelect = (value: string) => {
    setAddress(value);
    cords.setAddresses(value);
    handleClick(value);
  };

  // Log error status and clear dropdown when Google Maps API returns an error.
  const onError = (status: string, clearSuggestions: () => void) => {
    console.error('Google Maps API returned error with status: ', status);
    clearSuggestions();
  };

  const handleScrolled = useCallback(
    (e: any) => {
      e.preventDefault();
      const scrollValue = Math.floor(window.scrollY);
      if (scrollValue > 295) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    },
    [isScrolled]
  );

  useEffect(() => {
    window.addEventListener('scroll', (e) => handleScrolled(e));
    return () => {
      window.removeEventListener('scroll', () => handleScrolled);
    };
  }, [isScrolled]);

  return (
    <div
      className={`${styles['sv-smart-search-container']} ${
        isScrolled ? styles.scroll : ''
      }`}
    >
      <PlacesAutocomplete
        value={address}
        onChange={handleChange}
        onSelect={handleSelect}
        searchOptions={searchOptions}
        googleCallbackName="initTwo"
        onError={onError}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div className={styles['sv-smart-search']}>
            <input
              className={styles['sv-search-input']}
              {...getInputProps({
                disabled: false,
                placeholder:
                  locale === 'en'
                    ? SEARCH_PLACEHOLDER_EN
                    : SEARCH_PLACEHOLDER_FR,
              })}
            />
            <SearchOutlinedIcon
              className={styles['sv-search-icon']}
              style={{
                fontSize: '28px',
                marginLeft: 'unset',
                cursor: 'pointer',
              }}
              onClick={() => handleClick(address)}
            />
            <div className={styles['sv-find-storage']}>
              <FormattedMessage id="header.find-storage" />
            </div>
            <div className={styles['suggestions-dropdown']}>
              {loading ? <div>...loading</div> : null}
              {suggestions.map((suggestion, index) => {
                const style = {
                  backgroundColor: suggestion.active ? '#e9ecef' : '#fff',
                };
                return (
                  <div
                    className={styles.options}
                    {...getSuggestionItemProps(suggestion, { style })}
                    key={index}
                  >
                    {suggestion.description}
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    </div>
  );
};

export default SearchInput;
